import React from "react";

import "./BottomInfo.css"
import { Colors } from "../../../assets/theme/colors";

export default function BottomInfo(props) {
    if(props.feature) {    
        let featValues = props.feature.values_;  
        return (
            <div className={"bottomInfoContainer"} style={{background: Colors.QUATERNARY_COLOR}}>
                <p>{featValues.name}</p>
                <p>{featValues.id}</p>
                <p>{featValues.geometry.flatCoordinates }</p>
            </div>
        )
    } else {
        return null  
    }
}