// react
import React, { useState, useEffect, useRef } from 'react';

// openlayers
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import OSM from 'ol/source/OSM.js';

//css
import "./MapStyle.css"
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import "../../assets/icons/MarkYellow.png"

//const path = require('path')
const _ = require("lodash")

function MapWrapper(props) {

  // set intial state
  const [ map, setMap ] = useState()
  const [ featuresLayer, setFeaturesLayer ] = useState()
  const [ yellowFeaturesLayer, setYellowFeaturesLayer ] = useState()

  // pull refs
  const mapElement = useRef()
  
  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef()
  mapRef.current = map

  const markerHighlightWidth = 70;
  const markerHighlightHeight = 70;
  const markerWidth = 50;
  const markerHeight = 50;

  const highlightStyle = new Style({
    image: new Icon({
      src: "https://i.imgur.com/w9jGkow.png",
      width: Number(markerHighlightWidth),
      height: Number(markerHighlightHeight),
      //src: "file:\\\\assets\\icons\\badMarker.png",
      anchor: [0.5, 1]
    }),
    zIndex: 5
  })

  const markerStandardStyle = new Style({
    image: new Icon({
      src: "https://i.imgur.com/6s1dceo.png",
      width: Number(markerWidth),
      height: Number(markerHeight),
      //src: "file:\\\\assets\\icons\\badMarker.png",
      //src: path.join(__dirname, 'assets', 'icons', "badMarker.png"),
      anchor: [0.5, 1]
    }),
    zIndex: 3
  })

  const yellowMarkerStandardStyle = new Style({
    image: new Icon({
      src: "https://i.imgur.com/cKve0Op.png",
      width: Number(markerWidth),
      height: Number(markerHeight),
      //src: "file:\\\\assets\\icons\\badMarker.png",
      //src: path.join(__dirname, 'assets', 'icons', "badMarker.png"),
      anchor: [0.5, 1]
    }),
    zIndex: 3
  })

  // initialize map on first render - logic formerly put into componentDidMount
  useEffect( () => {

    // create and add vector source layer
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
      style: markerStandardStyle
    })

    // create and add vector source layer
    const yellowFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
      style: yellowMarkerStandardStyle
    })

    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
            source: new OSM(),
        }),
        initalFeaturesLayer,
        yellowFeaturesLayer       
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: [1877145.5647310177, 5030935.7155071795],
        zoom: 14
      }),
      controls: []
    })

    // set map onclick handler
    initialMap.on('click', handleMapClick)

    let selectedFeat = null
    initialMap.on('pointermove', function (e) {
      if (selectedFeat) {        
        selectedFeat.setStyle(undefined);
        selectedFeat = null
      }
      initialMap.forEachFeatureAtPixel(e.pixel, function (f) {
        selectedFeat = f
        selectedFeat.setStyle(highlightStyle);   //add your style here
        //console.log("HOVER", newSelectedFeature)
        return true;
      });
      
      props.onHoverFeature(selectedFeat)
    });

    // save map and vector layer references to state
    setMap(initialMap)
    setFeaturesLayer(initalFeaturesLayer)
    setYellowFeaturesLayer(yellowFeaturesLayer)

  },[])

  // update map if features prop changes - logic formerly put into componentDidUpdate
  useEffect( () => {

    if (props.features.length) { // may be null on first render

      // set features to map
      featuresLayer.setSource(
        new VectorSource({
          features: props.features // make sure features is an array
        })
      )

      // fit map to feature extent (with 100px of padding)
      // map.getView().fit(featuresLayer.getSource().getExtent(), {
      //   padding: [100,100,100,100]
      // })

    }

  },[props.features])

  // update map if features prop changes - logic formerly put into componentDidUpdate
  useEffect( () => {

    if (props.yellowFeatures.length) { // may be null on first render

      // set features to map
      yellowFeaturesLayer.setSource(
        new VectorSource({
          features: props.yellowFeatures // make sure features is an array
        })
      )

      // // fit map to feature extent (with 100px of padding)
      // map.getView().fit(featuresLayer.getSource().getExtent(), {
      //   padding: [100,100,100,100]
      // })

    }

  },[props.yellowFeatures])

  // map click handler
  const handleMapClick = (event) => {

    // get clicked coordinate using mapRef to access current React state inside OpenLayers callback
    //  https://stackoverflow.com/a/60643670
    const clickedCoord = mapRef.current.getCoordinateFromPixel(event.pixel);

    //console.log(clickedCoord)
    props.onClickMap(clickedCoord)

    // transform coord to EPSG 4326 standard Lat Long
    //const transormedCoord = transform(clickedCoord, 'EPSG:3857', 'EPSG:4326')
    
  }

  // render component
  return (      
    <div ref={mapElement} className="map-container"></div>
  ) 

}

export default MapWrapper