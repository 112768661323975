import React from 'react'
import MainToolbar from './MainToolbar'
import OlMap from './olMap/OlMap'

export default function MainContainer(props) {
    return(
        <div style={{height: "100%", width: "100%"}}>
            <MainToolbar/>
            <OlMap/>
        </div>
    )
}