import React, { useEffect, useState } from 'react'
import MapWrapper from './MapWrapper'
import MapSearchBar from './MapSearchBar'
import SideSlider from './components/SideSlider'

import { Feature } from 'ol';
import { Point } from 'ol/geom';
import BottomInfo from './components/BottomInfo';

export default function OlMap(props) {

    const [showRightSlider, setShowRightSlider] = useState(true)
    const [coordsToShow, setCoordsToShow] = useState([0,0])
    const [features, setFeatures] = useState([])
    const [yellowFeatures, setYellowFeatures] = useState([])    
    const [selectedFeature, setSelectedFeature] = useState(null)

    useEffect(() => {
        console.log("COMPONENTDIDMOUNT")
    }, [])

    useEffect(() => {
        console.log("ASDSADSADA", selectedFeature)
    }, [selectedFeature])

    useEffect(() => {
        console.log("FEATURES", features)
    }, [features])

    const OnClickSlider = (orderId) => {
        setShowRightSlider(orderId === showRightSlider ? -1 : orderId)
    }

    const onClickMap = (coord) => {
        setShowRightSlider(true);
        setCoordsToShow(coord)
        createMarker("TEST", coord)
    }

    const onHoverFeature = (f) => {      
        setSelectedFeature(f) 
    }

    let idFeature = 0

    //create marker
    const createMarker = (name, coord) => {
        console.log("MARKER CREATED", name, coord)

        const marker =  new Feature({
            geometry:  new Point(coord),
            name: name,
            id: idFeature++
        })
        console.log("FEATURES-CREATE MARKER", features)
        setFeatures(oldFeature =>  [...oldFeature, marker])
    }

    return(
        <div style={{height: "90%", width: "100%", backgroundColor: "yellow", position: "relative"}}>
            <SideSlider show={showRightSlider} coord={coordsToShow} onClose={() => {setShowRightSlider(-1)}} onOpen={OnClickSlider} order={0}></SideSlider>
            <SideSlider show={showRightSlider} coord={coordsToShow} onClose={() => {setShowRightSlider(-1)}} onOpen={OnClickSlider} order={1}></SideSlider>
            <SideSlider show={showRightSlider} coord={coordsToShow} onClose={() => {setShowRightSlider(-1)}} onOpen={OnClickSlider} order={2}></SideSlider>
            <SideSlider show={showRightSlider} coord={coordsToShow} onClose={() => {setShowRightSlider(-1)}} onOpen={OnClickSlider} order={3}></SideSlider>
            <MapSearchBar></MapSearchBar>
            <BottomInfo feature={selectedFeature}></BottomInfo>
            <MapWrapper features={features} yellowFeatures={yellowFeatures} onClickMap={onClickMap} onHoverFeature={onHoverFeature} /*selectedFeature={selectedFeature}*/ />
        </div>
    )
}