import React, { useEffect, useState } from "react";

import "./SideSlider.css"
import { Colors } from "../../../assets/theme/colors";

export default function SideSlider(props) {

    const transitionTime = 1

    const [zIndexSlider, setZIndexSlider] = useState("5")

    useEffect(() => {
        if(props.show === props.order) {
            setZIndexSlider("10")
        } else if(props.show !== props.order) {
            setTimeout(() => {
                setZIndexSlider("5")
            }, transitionTime*1000)
        }
    }, [props.show])

    return(
        <div style={{position: "absolute", zIndex: zIndexSlider, height: "calc(100% - 40px)", width: "calc(30vw - 40px)", transition: transitionTime+"s", right: props.show === props.order ? "0" : "-30vw", background: Colors.QUATERNARY_COLOR, padding: "20px"}}>
            <div onClick={props.onClose} className="sideSliderCloseButton"></div>
            <p>{props.coord[0]}</p>
            <p>{props.coord[1]}</p>
            <div 
                className="sideSliderSidelabel" 
                style={{
                    backgroundColor: props.order % 2 === 0 ? Colors.SECONDARY_COLOR : Colors.TERTIARY_COLOR,
                    bottom: props.order*(50 + 20) + 20 + "px" 
                }} 
                onClick={() => {props.onOpen(props.order)}}
            ></div>
        </div>
    )
}