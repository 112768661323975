import React from 'react'

import './App.css'
import MainContainer from './components/MainContainer'

function App() {
  return (
    <div style={{height: "100vh", width: "100vw"}}>
      <MainContainer/>
    </div>
  )
}

export default App
